.clickable {
  cursor: pointer;
}

.open {
  color: #3B5998;
  font-style: italic;
}

.closed {
  color: red;

}

#modal-dialog-onboarding {
  margin: 1.75rem auto;
}

/* 1. BUSINESS INFORMATION */

.bi-main-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.bi-column-1 {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.bi-column-2 {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.btn:hover {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 1280px) {
  .bi-main-container {
    justify-content: center;
    flex-direction: column
  }
  .bi-column-1 {
    width: 100%;
  }
  .bi-column-2 {
    padding-top: 20px;
    width: 100%;
  }
}


/* BUSINESS HOURS component */

.bh-title {
  display: flex;
  width: 100px;
  flex-direction: row;
  padding-bottom: 10px;
}

.bh-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2px;
}

  .bh-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
  }

    .bh-day-title {
      display: flex;
      justify-content: space-between;
    }

    .bh-day {
      display: flex;
      width: 75px
    }

    .bh-day-status {
      height: calc(1em + 0.9375rem + 2px);
      display: flex;
      align-items: center;
      padding-left: 5px;
    }

    .bh-hours-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .bh-hours {
      display: flex;
      width: 203.5px;
      /* justify-content: flex-end; */
    }

/* 2. CONTENT */

.content-main-container {
  display: flex;
  width: 99%;
  justify-content: space-between;
}

.content-column-1 {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.content-column-2 {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.content-column-3 {
  display: flex;
  flex-direction: column;
  width: 33%;
}

@media (max-width: 1280px) {
  .content-main-container {
    display: flex;
    justify-content: center;
    flex-direction: column
  }
  .content-column-1 {
    width: 100%;
  }
  .content-column-2 {
    padding-top: 20px;
    width: 100%;
  }
  .content-column-3 {
    padding-top: 20px;
    width: 100%;
  }
}

/* 3. IMAGES/GRAPHICS  */

.img-main-container {
  display: flex;
}

.img-column-1 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 2%;
  width: 50%;
}

.img-column-2 {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 2%;
  width: 10%;
}

@media (max-width: 950px) {
  .img-main-container {
    display: flex;
    justify-content: center;
    flex-direction: column
  }
  .img-column-1 {
    width: 100%;
  }
  .img-column-2 {
    padding-top: 20px;
    width: 100%;
  }
}

.no-hover-button {
  background-color: #0c8842 !important; /* Aplica el color de fondo */
  color: white !important;             /* Ajusta el color del texto */
  border-color: #0c8842 !important;    /* Asegura que el borde sea del mismo color */
}

.no-hover-button:hover {
  background-color: #0c8842 !important; /* Evita cambios en el hover */
  color: white !important;
  border-color: #0c8842 !important;
}

.no-hover-button:disabled {
  background-color: #a5d6a7 !important; /* Estilo opcional para estado deshabilitado */
  color: #ffffff !important;
  border-color: #a5d6a7 !important;
}