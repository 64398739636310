.tag {
  list-style-type: none;
  margin: .2em .2em;
  display: inline-flex;
  align-items: center;
  background-color: #0168fa;
  color: #fff;
  padding: 0 .5rem;
  border-radius: 1px;
  height: 1.5rem;
}

.remove-tag::after {
  content: "x";
  padding: 0px 6px;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  width: 10px;
  position: relative;
  opacity: .5;
}

.remove-tag:hover::after {
  opacity: 1;
}