@import '../../theme/theme.scss';

.calendar-wrapper {
  .calendar-sidebar {
    .calendar-sidebar-header {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .calendar-sidebar-body {
      .calendar-nav {
        button {
          display: flex;
          align-items: center;
          color: #1b2e4b;
          padding: 6px 10px;
          border-radius: 0.25rem;
          text-decoration: none;
          background-color: transparent;
          border: none;
          outline: none;
          &:hover {
            background-color: #f5f6fa;
          }
          span {
            position: relative;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            color: #fff;
            margin-right: 10px;
            border: 2px solid transparent;
          }
        }
      }
    }
  }
  .calendar-content {
    .fc-header-toolbar {
      height: 55px;
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(72, 94, 144, 0.16);
      padding: 0 15px;
    }
  }
}
.calendar-nav {
  button {
    span {
      &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 6px;
        height: 6px;
        background-color: inherit;
        border-radius: 100%;
        display: none;
      }
    }
  }
  button.posted {
    span {
      background-color: $posted-color;
    }
  }
  button.scheduled {
    span {
      background-color: $scheduled-color;
    }
  }
  button.draft {
    span {
      background-color: $draft-color;
    }
  }
  button.app {
    span {
      background-color: $app-color;
    }
  }
  button.prompt {
    span {
      background-color: $prompt-color;
    }
  }
}
.calendar-content {
  z-index: -1;
  .active {
    background-color: $secondary-color;
  }
  button {
    background-color: $primary-color;
    &:hover {
      background-color: $secondary-color;
    }
  }
}
.calendar-loading {
  height: 100vh;
  width: 100%;
  z-index: 1000;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.486);
}

h2{
  font-size: 1.75em !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  color: #001737 !important;
}

.fc-customTitle-button,
.fc-customTitle-button:hover,
.fc-customTitle-button.btn-primary,
.fc-customTitle-button.btn-primary:hover {
  cursor: auto !important;
  background-color: transparent !important;
  border: none !important;
  font-size: 1.75em !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  color: #001737 !important;
}

.fc-content{
  overflow: hidden !important;
}

.fc-content-custom {
  white-space: normal !important;
  max-height: 120px;
  text-align: left;
  padding-left: 10px;
  padding-top: 5px;
}


.custom-checkbox-lg .custom-control-input {
  width: 15px;
  height: 15px;
}

.custom-checkbox-lg .custom-control-label {
  padding-top: 2px; /* Asegura el espaciado correcto entre el checkbox y el texto */
}

.custom-checkbox-lg custom-control-button {
  padding-top: 8px; /* Asegura el espaciado correcto entre el checkbox y el texto */
}

.btn-active {
  background-color: #e5e9f2 !important;
}

.btn-active:hover {
  color: #fff;
  background-color: #677793 !important;
}

.btn-inactive {
  pointer-events: none;
}