.schedule-container {
  margin-bottom: 5px;
}

.schedule-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.schedule-day {
  flex: 1;
}

.schedule-status {
  cursor: pointer;
  font-weight: bold;
}

.schedule-hours {
  display: flex;
  align-items: center;
  gap: 10px;
}

.business-hours {
  /* padding: 10px; */
}

.business-hours-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.invalid-feedback {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}
