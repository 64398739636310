/* * {
  font-family: 'Plex Sans', sans-serif;
} */

.not-approved {
  background-color: gray;
  color: white;
  border: none;
}
.not-approved:hover {
  background-color: rgb(82, 82, 82);
  color: white;
  border: none;
}

.approved {
  background-color: green;
  color: white;
  border: none;
}
.approved:hover {
  background-color: green;
  color: white;
  border: none;
  cursor:not-allowed;
}

.approve-container {
  text-align: center;
  margin: 20px;
}

.clock-icon-box {
  position: relative;
  display: flex;
  align-items: center;
  left: 8em;
}

.clock-icon {
  height: 2em;
  width: auto;
  opacity: 0.5;
}

.clock-icon:hover {
  opacity: 0.7;
}

.oclock-yellow {
  color: #f0ad4e;
  fill: #f0ad4e;
}

.text-clock {
  width: 6.5em;
  font-size: 0.8em;
  margin: 0;
}

.cards {
  margin-top: 30px;
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(34em, auto);
  justify-items: center;
  font-family: 'Plex Sans', sans-serif;
  font-weight: 500;
  color: gray;
}
.icon-gmb {
  height: 4.9rem;
}

.icon {
  border-radius: 100px;
  border: solid #ffffff;
  padding: 1px;
  height: 6.7rem;
  width: 6.7rem;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-active {
  border: solid #3766a8;
  box-shadow: 0px 0px 5px #3766a8;
}
.icon-no-active {
  filter: grayscale(100%) opacity(50%);
}
.icon-yelp {
  height: 6rem;
}

.review-card {
  -ms-overflow-style: none;
  max-width: 20rem;
  height: 30rem;
  display: flex;
  flex-direction: column;
  width: 25rem;
  /* border: gray 1px solid;
  border-radius: 15px; */
}

.review-card-content {
  border: 1px solid #e2e5ed;
  height: 28rem;
  border-radius: 0px 0px 10px 10px;
  padding: 0 10px 0 10px;
  width: 100%;
  /* overflow-y: scroll; */
}

.review-card-comment {
  overflow-y: scroll;
}
.review-card-comment::-webkit-scrollbar {
  display: none;
}
.review-card-header {
  height: 78px;
  display: flex;
  justify-items: center;
  align-items: center;
  width: 100%;
}
.review-card-header-img {
  height: 100px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  z-index: 1;
  position: relative;
}
.review-card-avatar {
  width: 100%;
  display: flex;

  height: 60px;
  align-content: center;
}
.review-card-rating {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.review-card-img-avatar {
  width: 80px;
  position: relative;
  height: 80px;
  z-index: 0;
  left: 6.5rem;
  top: -40px;
}
.review-card-text {
  margin-top: 10px;
  font-size: 14px;
  /* height: 10px; */
  /* overflow: scroll; */
}

.breadcrumb--reviews {
  font-size: 10px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', Roboto, sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.slash {
  margin: 0 2em;
}

.select {
  color: #0168fa !important;
}

.modal-body__right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#setting-icon {
  fill: #8a93a1;
  transition: fill 0.5ms linear;
}

#setting-icon:hover {
  fill: black;
}

#Chart-icon {
  stroke: #8a93a1;
  transition: fill 0.5ms linear;
}

#Chart-icon:hover {
  stroke: black;
}

#location-name {
  z-index: 100;
  color: white;
  padding: 0 10px;
  font-size: 16px;
  font-weight: bold;
}

#automatic-answer {
  z-index: 101;
  height: 25em;
  overflow-y: scroll;
}

#icon-bg {
  margin-left: 1.2em;
}
#icon-Chart {
  margin-left: 50em;
}

.list-group {
  position: unset !important;
}

.list-group-item {
  background-color: #eaab34;
  color: white;
}

@media only screen and (max-width: 768px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
