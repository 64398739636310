@import '../../theme/theme.scss';

.btn-group {
  .dropdown-toggle {
    background-color: $primary-color;
  }
  a {
    background-color: $primary-color;
    &:hover {
      background-color: $secondary-color;
    }
  }
}

.progress-bar.indeterminate {
  position: relative;
  animation: progress-indeterminate 2s linear infinite;
}

@keyframes progress-indeterminate {
  from {
    left: -35%;
    width: 35%;
  }
  to {
    left: 100%;
    width: 35%;
  }
}
