.drowpown-body {
  max-height: 20rem;
  overflow-x: hidden;
  overflow: scroll;
  scrollbar-color: #3766a8 #fff;
  scrollbar-width: thin;
  
}

.drowpown-body::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  height: 2px;
}

.drowpown-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3766a8;
}

.clear-all {
  color: #3766A8;
  cursor: pointer;
  font-size: 0.8rem;
}

.clear-all:hover {
  text-decoration: underline;
}