.crop-container {
  height: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Asegura que no se desborde */

}
.reactEasyCrop_Container {
  position: relative !important;
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important; /* Previene que salga del contenedor */
  left: 0 !important;
  top: 0 !important;
}

.modal-dialog .col-md-8 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
  height: 24rem; /* Asegura el alto del contenedor */
  position: relative; /* Importante para el posicionamiento de hijos absolutos */
  overflow: hidden; /* Prevenir el desbordamiento */
}

.modal-dialog .col-md-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  min-height: 400px;
}

.modal-dialog .crop-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.save-btns {
  position: absolute !important; 
  display: flex !important;
  justify-content: space-between !important; /* Espaciado entre los botones */
  align-items: center !important; /* Alinea verticalmente los botones */
  width: 100% !important;
  padding: 0 1rem !important; /* Agrega un poco de margen lateral */
  left: 0 !important;
  bottom: 1rem !important; /* Un poco de separación del borde inferior */
}
.save-btns .btn {
  margin: 0 0.5rem; /* Espaciado entre botones */
}
.col-md-4 {
  position: relative;
  height: 100%; /* Asegura que ocupe toda la altura disponible */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuye los elementos: uno arriba, uno abajo */
}