@import '../../theme/theme.scss';

.btn-save-draft {
    background-color: $primary-color;
    &:hover {
        background-color: $secondary-color;
    }
}

.btn-publish-post {
    background-color: $success-color;
    &:hover {
        background-color: $secondary-color;
    }
}