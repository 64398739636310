.info-field {
  padding: 10px;
}

.divider {
  margin: 0px 10px;
}

.edit-icon-container {
  margin-left: 10px;
}

.title {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.buttons-container {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid rgba(72, 94, 144, 0.16);
  flex-direction: row;
}

  .buttons {
    padding-left: 25px;
  }


/* Phone Number */
.phoneField {
  /* border: 0px; */
}

.client-profile input {
  margin-bottom: 0px;
  font-size: 0.875rem;
}

input {
  border-color: transparent;
  border-width: 0.1px;
}

:focus-visible {
  outline: none;
}

/* Opening Date */
.openingDate {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* Hours */
.hours {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
}

.label {
  flex-grow: 1
}
