.input-tags, .input-tags:focus-visible {
  border: none;
  outline: none;
}

.scroll-tags {
  overflow-y: scroll;
}

.bigger-box-ig-tag {
  height: 8rem;
}

.smaller-box-ig-tag {
  height: 4rem;
  font-size: .9em;
}

.cursor {
  cursor: pointer;
}
