.df-table {
  height: 54px;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: #ffffff;
  width: 100%;
  z-index: 1;
  left: calc(50%);
  transform: translateX(-50%);
  padding: 0 15px 0 20px;
}
.df-table .search-form {
  border-right: 1px solid rgba(72, 94, 144, 0.16);
  width: 262px;
}
.df-table .feather-search {
  color: #8392a5;
  align-self: center;
  width: 18px;
  stroke-width: 2.8px;
  margin-right: 10px;
  margin-top: -2px;
}
.df-table .search-label {
  margin: 0;
  height: 54px;
  border: 0px;
}
.df-table .search-label input {
  height: inherit;
  border: none !important;
  border-radius: 0;
}
.table-dashboard {
  margin-top: 55px;
}

.react-bootstrap-table-pagination {
  margin: 0px;
}
