@import '../../theme/theme.scss';

.bg-development {
  background-color: $warning-color;
}
.bg-production {
  background-color: $primary-color;
}

.navbar-header {
  border-bottom-color: transparent;
  .nav-link {
    color: rgba(255, 255, 255, 0.7);
    &:hover {
      opacity: 1;
    }
  }
  .navbar-menu {
    display: flex;
    justify-content: center;
  }
  .navbar-brand {
    padding: 0 0 0 20px !important;
  }
}
