.device-emulator-container {
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  width: 200px;
}

.mobile-chrome {
  background: url('./assets/IPhone7.png');
  background-repeat: no-repeat;
  background-size: 250px 450px;
  height: 450px;
  width: 250px;
  /* overflow: hidden; */
}

.mobile-frame {
  position: relative;
  top: 40px;
  left: 18px;
  width: calc(250px - 37px);
  height: calc(150px - 140px);
  border: none;
  border-radius: 5px;
}

.chat {
  font-family: helvetica;
  width: 260px;
  height: 300px;
  display: flex;
  flex-direction: column;
}

.messages {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.messages::-webkit-scrollbar {
  display: none;
}
.custom-sms {
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.custom-sms::-webkit-scrollbar {
  display: none;
}
.message {
  border-radius: 20px;
  padding: 13px 10px;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
}

.yours .message.last:before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.yours .message.last:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 20%;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  position: relative;
}

.mine .message.last:before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}
