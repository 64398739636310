// Adler Social style guide
$primary-color: #3766a8 !important;
$secondary-color: #445268 !important;
$success-color: #5cb85c !important;
$danger-color: #d9534f !important;
$warning-color: #f0ad4e !important;
$info-color: #5bc0de !important;
// Calendar color conventions
$posted-color: #f9b47f !important;
$scheduled-color: #039be5 !important;
$draft-color: #7987a1 !important;
$app-color: #10b759 !important;
$prompt-color: #B181ED !important;

// Ovewritten styles
.swal2-modal .swal2-actions .swal2-confirm {
	color: #fff !important;
	background-color: $primary-color;
	border-color:$primary-color;
    &:hover {
        background-color: $secondary-color;
	    border-color:$secondary-color;
    }
}