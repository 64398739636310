.social-networks-section .profile-info-list {
  display: flex;
  gap: 20px; /* Espacio entre los elementos */
  flex-wrap: wrap; /* Para que no se salgan de la pantalla si son demasiados */
}

.social-networks-section .profile-info-list li {
  display: flex;
  align-items: center;
  margin: none;
 /* Espacio entre el icono y el texto */
}

.profile-info-list li + li {
  margin-top: 0px;
}

.social-networks-section ul {
  margin-bottom: 0px;
}

.social-networks-section ul li a {
  min-width: 30px; /*  Ajusta el valor según lo que necesites */
  display: inline-block; /* Necesario para que funcione min-width */
  text-align: left; /* Alineación del texto */
  white-space: nowrap; /* Para evitar que el texto se divida en varias líneas */
  overflow: hidden; /* Si el texto es demasiado largo, lo cortará */
  text-overflow: ellipsis; /* Muestra "..." si el texto es muy largo */
}

.badge-danger {
  background-color: red;
}

.profile-info-list svg {
  color: darkgray;
  fill: darkgray;
}

.social-networks-section a, .social-networks-section span {
  display: inline-block;
  max-width: 150px; /* Ajusta el tamaño según lo que necesites */
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  overflow: hidden; /* Oculta el texto que se sale del contenedor */
  text-overflow: ellipsis; /* Agrega "..." al final si el texto es muy largo */
}