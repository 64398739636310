.carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel-images {
    position: relative;
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .slide {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
  }
  
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
  }
  
  .prev-button,
  .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 18px;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .prev-button {
    left: 10px;
  }
  
  .next-button {
    right: 10px;
  }
  
  .dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #bbb;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #333;
  }