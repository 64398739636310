.horizontalDivider {
  padding: 20px;
}

.verticalDivider {
  padding-right: 20px;
}

.fullSize {
  height: 100%;
  width: 100%;
}

.star {
  color: #FFBB00;
}

.opinionHeader {
  padding-bottom: 10px;
}

.profilePhoto {
  padding-right: 15px;
}

.imgR {
  width: 100px;
  /* object-fit: cover; */
}

.opinion {
  padding: 20px;
  margin-bottom: 10px;
}

.name {
  padding-right: 10px;
}

.response {
  padding: 20px;
}