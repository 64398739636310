.modal-posts-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100vh;
}

.bnt-l-follow,
.bnt-r-follow {
  position: fixed;
  z-index: 2000;
  background: none;
  border: none;
}
.button-Restart {
  height: 30px;
  width: 20px;
  fill: #596882 !important;
  transition: fill 0.5ms linear;
}
.bnt-l-follow {
  left: 18vw;
}

.bnt-r-follow {
  right: 18vw;
}

.icon-modal {
  height: 4rem;
  fill: white;
}

#prev-icon {
  transform: rotate(180deg);
}

#timestatus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 968px) {
  .bnt-l-follow {
    left: 0;
  }

  .bnt-r-follow {
    right: 0;
  }
}
