.card-app-photos .modal-dialog {
  margin-top: 4.5rem;
}

.img-wrapper {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 100%;
  /* width: 200; */
  height: 500px;
}
.imgModal {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  /* background-repeat: no-repeat; */
}
.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-app-photos {
  height: 23rem;
}
.card-app-description {
  height: 7rem;
  margin-top: 15px;
}
.card-app-description .text-wrapper {
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-color: #3766a8 #fff;
  scrollbar-width: thin;
}
.card-app-description .text-wrapper::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  height: 2px;
}
.card-app-description .text-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3766a8;
}

.card-app-photos .modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-app-photos .input-url {
  width: 23.6rem;
}

.card-app-photos .images-wrapper {  
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-color: #3766a8 #fff;
  scrollbar-width: thin;
}

.card-app-photos .images-wrapper::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  height: 2px;
}

.card-app-photos .images-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3766a8;
}

.card-app-photos .card-columns {
  column-gap: 0px;
}

.card-app-photos .card-columns .card {
  margin: 0px !important;
  border-radius: 0px !important;
}
