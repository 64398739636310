.tags-input-container-onboarding {
  padding: 0.5em;
  border-radius: 6px;
  width: 100%;
  min-height: 150px;
  max-height: 165px;
  align-items: center;
  background-color: #f3f4f7;
}

.capitalize {
  text-transform: capitalize;
}
.tags-container {
  overflow-y: scroll;
  max-height: 100px;
  margin-bottom: 4px;
}

.tag-item-onboarding {
  margin-bottom: 0.45em;
  padding: 0.2em 0.7em;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}

.red-tag {
  background-color: #ff0000;
}

.blue-tag {
  background-color: #3b5998;
}

.tag-item-onboarding .close-tag {
  height: 16px;
  min-width: 16px;
  background-color: #d9d9d9;
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
}

.tags-input-onboarding {
  border: none;
  outline: none;
  width: 100%;
  background-color: inherit;
  height: 28px;
  margin: 0px;
  vertical-align: top;
  resize: none;
}

.tags-container::-webkit-scrollbar {
  width: 4px;
  margin-right: 20px;
  margin-left: -10px;
}

.tags-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 4px;
  background-color: #8392a5;
  margin-right: 20px;
}

.font {
  color: #8392a5;
  font-weight: 450;
  white-space: nowrap;
}

.input-days {
  background-color: #f3f4f7;
  color: #3b5998;
  border: none;
}

.divider {
  color: #959ca9;
  font-size: 18px;
  margin: 0 10px;
  padding-top: 5px;
  line-height: 0.75;
  display: flex;
  align-items: center;
}

.square {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 6px;
}
