.clickable {
    cursor: pointer;

}

.open {
    color:#3B5998;
    /* font-weight: bold; */

    font-style: italic;
}

.closed {
    color:red;

}

#modal-dialog-onboarding {
    max-width: 1200px !important;
    margin: 1.75rem auto;
  }