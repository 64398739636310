@import '../../theme/theme.scss';

.section-explore {
  .nav-classic {
    .nav-link {
      svg {
        fill: $secondary-color;
      }
    }
    .expl-selected {
      color: $primary-color;
      font-weight: 500;
      svg {
        fill: $primary-color;
      }
    }
  }
}
.group-button {
  display: block;
  background-color: #fff;
  border: 1px solid #c0ccda;
  padding-top: 3px;
  padding-bottom: 1px;
  padding-left: 8px;
  padding-right: 8px;
  color: $primary-color;
  border-radius: 0.25rem;
}
.client-profile {
  .delete-btn {
    background-color: $primary-color;
  	border-color: $primary-color;
    &:hover {
      background-color: $secondary-color;
    	border-color: $secondary-color;
    }
  }
  input {
    margin-bottom: 10px;
    font-size: 0.875rem;
  }
  .df-table {
    transform: none;
    width: 100%;
    left: auto;
    .client-search {
      width: 100%;
    }
  }
  .merge-fields-table {
    table {
      overflow: auto;
    }
  }
  .profile-right-sidebar {
    border: 1px solid rgba(72, 94, 144, 0.16);
    width: 100%;
    height: calc(100vh - 154px);
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px;
      height: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(72, 94, 144, 0.16);
    }
  }
  .profile-sidebar {
    border: 1px solid rgba(72, 94, 144, 0.16);
    width: 100%;
    height: calc(100vh - 154px);
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px;
      height: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(72, 94, 144, 0.16);
    }
    .row-info-client {
      padding-right: 1rem;
      .skill-sets {
        justify-content: space-between;
        margin-right: 1rem;
      }
    }
  }
}
.skill-sets {
  .profile-skillset {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.btn-active {
  background-color: #e5e9f2 !important;
}

.btn-active:hover {
  color: #fff;
  background-color: #677793 !important;
}

.btn-inactive {
  pointer-events: none;
}
